<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width d-flex flex-column justify-center align-center">
      <ModalHeader
        :content-sections="['actions', 'title', 'logo']"
        :actions-tag="'click_reg_user-log-in_back-button'"
        :title-text="'user_register.welcome.login'"
        small-logo
        @back="backMethod()"
      />
      <ModalContent
        :class="inWaitingList && !mobile ? 'deg-waiting-list--mobile' : ''"
        :content-sections="['registerInput', 'google', 'textLink']"
        :error-types="showPhoneRegister || !identification ? [] : ['email']"
        :input-section="inputContent"
        :recover-link-text="'views.login.to-forgot-password'"
        :input-text-select="showPhoneRegister ? true : false"
        :text-select-index="0"
        :text-select-items="listCodeArea"
        :icon-select-items="['mdi-email-outline', 'mdi-phone-outline']"
        :icon-select-color="'#7a7a7a'"
        :google-input-tag="'click_reg_user-log-in_google-button'"
        :recover-link-tag="'click_reg_user-log-in_forgot-password-textlink'"
        @signInGoogle="signInGoogle()"
        @setValue="setValue"
        @isInvalidEmail="isInvalidEmail"
        @toRecovery="$emit('toRecovery')"
        @setTextSelectIconValue="setLoginOption"
        @setTextSelectValue="setDialCode"
      />
    </div>
    <CallToActions
      :class="inWaitingList && !mobile ? 'deg-waiting-list--mobile' : ''"
      :link-text="'views.login.to-register'"
      :link-text-tag="'click_reg_user-log-in_sing-in-textlink'"
      :types="['main', 'question', 'link']"
      :main-button-text="'user_register.welcome.login'"
      :main-button-tag="'click_reg_user-log-in_log-in-button'"
      :active-value="isActive"
      :loader="loginLoading || socialLoginActive"
      :question-text="'views.login.account'"
      :spaced="false"
      @continue="recaptcha()"
      @toLink="$emit('toRegister')"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import listCodeArea from '@/locales/code_area.json';
import { SignUpUser } from '@/utils/googleInit';
import trackMixPanel from '@/utils/mixpanel';
import * as SentryBrowser from '@sentry/browser';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LoginModal',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  props: {
    inWaitingList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputContent: [
        {
          value: null,
          noInfoError: false,
          placeholder: 'views.login.name.email',
          show: true,
          selectValue: '+57',
          inputType: 'email',
        },
        {
          value: null,
          noInfoError: false,
          placeholder: 'views.login.name.password',
          show: true,
          inputType: 'password',
          password: 1,
        },
      ],
      identification: null,
      invalidEmail: null,
      password: null,
      googleAuth: false,
      showPhoneRegister: false,
      dialCode: '+57',
      socialLoginActive: false,
    };
  },
  computed: {
    ...mapGetters({
      loginLoading: 'authentication/loginLoading',
      authDone: 'authentication/authDone',
      inDigitalEnrollment: 'digitalEnrollment/inDigitalEnrollment',
      sessionUUID: 'authentication/sessionUUID',
      identificationResult: 'authentication/identificationResult',
      googleIdentificationResult: 'authentication/googleIdentificationResult',
    }),
    phoneData() {
      if (this.showPhoneRegister) {
        return {
          number: this.identification,
          dialCode: this.dialCode,
        };
      }
      return null;
    },
    email() {
      if (!this.showPhoneRegister) {
        return this.identification;
      }
      return null;
    },
    isActive() {
      if ((this.identification && this.password) || this.socialLoginActive) {
        return true;
      }
      return false;
    },
    listCodeArea() {
      const dialCodes = listCodeArea.countries.map((country) => country.dial_code);
      return dialCodes;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    authDone: {
      handler(newVal) {
        if (newVal) {
          if (!this.inDigitalEnrollment && !this.inWaitingList) {
            this.getModalFlowInfo().then(() => {
              /* if we have applications
              this.retrieveApplicationsByUuid()
                .then(() => {
                  this.$router.push('/map');
                })
                .catch(() => {
                  this.$router.push('/map');
                });
              */
              // this.$router.push('/map');
            });
          }
        }
      },
    },
    sessionUUID(newValue) {
      if (newValue) {
        this.setLoginLoading({ bool: false });
        if (this.inWaitingList || this.inDigitalEnrollment) {
          this.$emit('nextStep');
        }
      }
      SentryBrowser.setUser({ id: newValue });
      this.$mixpanel.identify(this.sessionUUID);
      this.$mixpanel.people.set_once({
        $email: this.email,
      });
      trackMixPanel('login');
    },
    identificationResult(newVal) {
      const { done, success, verify } = newVal;
      if (done) {
        if (success && verify) {
          this.error(this.$t('views.login.error.verification'));
          this.$emit('toVerification');
        }
        if (!success) {
          this.setLoginLoading({ bool: false });
        }
      }
    },
    googleIdentificationResult(newVal) {
      const {
        done, success, newUser, canLink,
      } = newVal;
      if (done) {
        this.setLoginLoading({ bool: false });
        this.socialLoginActive = false;
        if (success && newUser) {
          this.$emit('skipVerification');
        } else if (canLink) {
          this.$emit('toAccountLinking');
        } else if (success && this.inWaitingList) {
          this.$emit('nextStep');
        } else if (success) {
          this.$router.push('/map');
        }
      }
    },
  },
  mounted() {
    this.setForcedStep({ step: null });
  },
  methods: {
    ...mapActions({
      setLoginLoading: 'authentication/setLoginLoading',
      doGoogleIdentification: 'authentication/doGoogleIdentification',
      doIdentification: 'authentication/doIdentification',
      error: 'utils/error',
      setForcedStep: 'userRegister/setForcedStep',
      getModalFlowInfo: 'userRegister/getModalFlowInfo',
      setSimulationModalCompleted: 'simulation/setSimulationModalCompleted',
    }),
    backMethod() {
      this.$emit('backStep');
    },
    isInvalidEmail(bool) {
      this.invalidEmail = bool;
      return this.invalidEmail;
    },
    async recaptcha() {
      if (this.identification && this.password) {
        this.setLoginLoading({ bool: true });
        await this.$recaptchaLoaded();
        const captcha = await this.$recaptcha('login');
        await this.tryLogin(captcha);
      } else {
        if (!this.identification || this.invalidEmail) {
          if (!this.identification) {
            this.inputContent[0].noInfoError = true;
          }
        }
        if (!this.password) {
          this.inputContent[1].noInfoError = true;
        }
      }
    },
    async tryLogin(captcha) {
      const identification = {
        email: this.email,
        phoneData: this.phoneData,
        password: this.password,
        hotjar: this.$hj,
        captcha,
      };
      this.doIdentification({ identification, linkAccounts: this.inWaitingList }).catch(() => {
        this.setLoginLoading({ bool: false });
      });
    },
    async signInGoogle() {
      this.googleAuth = true;

      await SignUpUser().then((response) => {
        this.socialLoginActive = true;
        this.setLoginLoading({ bool: true });
        this.doGoogleIdentification({
          googleAuth: response,
        });
      });
      this.setSimulationModalCompleted(false);
    },
    setValue(mainValue, passwordInput, index) {
      if (passwordInput === 0) {
        this.identification = mainValue;
      }
      if (passwordInput === 1) {
        this.password = mainValue;
      }
      this.inputContent[index].value = mainValue;
      this.inputContent[index].noInfoError = false;
    },
    setLoginOption(index, value) {
      if (index === 0) {
        if (value === 'mdi-phone-outline') {
          this.inputContent[0].placeholder = 'views.login.name.phone';
          this.showPhoneRegister = true;
          this.inputContent[0].inputType = 'number';
          return this.showPhoneRegister;
        }
        this.inputContent[0].inputType = 'email';
        this.inputContent[0].placeholder = 'views.login.name.email';
        this.showPhoneRegister = false;
        return this.showPhoneRegister;
      }
      return null;
    },
    setDialCode(index, value) {
      if (index === 0) {
        this.dialCode = value;
      }
      return this.dialCode;
    },
  },
};
</script>
