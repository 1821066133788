<template>
  <section class="bg-primary-dark w-full d-flex flex-column">
    <GmapMap
      id="map"
      :center="getLocationStudent"
      :zoom="13.3"
      :options="{
        disableDefaultUI: true,
        clickableIcons: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        scrollwheel: false,
        draggable: false,
        styles: stylesGoogleMap,
        gestureHandling: 'greedy',
      }"
      class="map"
    >
      <GmapCustomMarker id="student-location" :marker="getLocationStudent">
        <img
          :src="require(`@/assets/feedback/marker-student.svg`)"
          width="18"
          alt="marker student"
        />
      </GmapCustomMarker>
      <template v-if="false">
        <!-- Marker recommendations -->
        <GmapCustomMarker
          v-for="(marker, index) in getLocationRecommendations"
          :key="index + Math.random()"
          :marker="marker"
        >
          <img
            :src="require(`@/assets/feedback/marker-white.svg`)"
            width="18"
            alt="marker student"
          />
        </GmapCustomMarker>
        <!-- Marker recommendations payment and performance -->
        <GmapCustomMarker
          v-for="(marker, index) in getLocationRecommendationsPaymentPerformance"
          :key="index + Math.random()"
          :marker="marker"
        >
          <img
            :src="require(`@/assets/feedback/marker-blue.svg`)"
            width="18"
            alt="marker student"
          />
        </GmapCustomMarker>
      </template>
      <!-- Marker applications -->
      <GmapCustomMarker
        v-for="(marker, index) in getLocationApplications"
        :key="index + Math.random()"
        :marker="marker"
      >
        <img
          :src="require(`@/assets/feedback/marker-favorite.svg`)"
          width="18"
          alt="marker student"
        />
      </GmapCustomMarker>
      <GmapCircle
        id="student-location-radius"
        :center="getLocationStudent"
        :radius="3000"
        :visible="true"
        :options="mapCircle"
      />
    </GmapMap>

    <!-- <div class="w-full d-flex flex-column align-center justify-center py-7">
      <div class="w-full d-flex flex-row justify-start" style="max-width: 300px;">
        <img
          src="@/assets/feedback/marker-student.svg"
          width="18"
          alt="marker student"
        />
        <TextAtom
          value="Tu zona de interés "
          color="secondary-light"
          font="inter"
          class="ml-2"
        />
      </div>
      <div class="w-full d-flex flex-row justify-start mt-5" style="max-width: 300px;">
        <img
          src="@/assets/feedback/marker-favorite.svg"
          width="18"
          alt="marker student"
        />
        <TextAtom
          value="Establecimientos a los que postulate"
          color="secondary-light"
          font="inter"
          class="ml-2"
        />
      </div>
    </div> -->
  </section>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import stylesGoogleMap from '@/assets/mapStyle.json';
// import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'FeedbackMap',
  components: {
    GmapCustomMarker,
    // TextAtom,
  },
  props: {
    locationStudent: {
      type: Object,
      required: true,
    },
    recommendations: {
      type: Array,
      required: true,
    },
    applications: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stylesGoogleMap,
      mapCircle: {
        strokeColor: 'rgba(255, 255, 255, 0.9)',
        strokeWeight: 2,
        fillColor: 'rgba(255, 255, 255, 0.6)',
        radius: 3000,
      },
    };
  },
  computed: {
    getLocationStudent() {
      return {
        lat: this.locationStudent.address_lat,
        lng: this.locationStudent.address_lon,
      };
    },
    getLocationRecommendationsPaymentPerformance() {
      return this.recommendations.filter((recommendation) => recommendation.map_category === 'highlighted').map(({ lat, lon }) => ({ lat, lng: lon }));
    },
    getLocationRecommendations() {
      return this.recommendations?.map(({ lat, lon }) => ({ lat, lng: lon }));
    },
    getLocationApplications() {
      return this.applications?.map((application) => ({
        lat: application?.campus?.location.lat,
        lng: application?.campus?.location.lon,
      })) || [];
    },
  },
  mounted() {
    console.log('application', this.applications);
  },
};
</script>
