/* eslint-disable no-param-reassign */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import CONFIG from '@/config';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import i18n from '@/plugins/i18n';
import router from '@/router';

const getSearchIndex = (index) => {
  const searchEnv = CONFIG.apiEnvironment === 'production' ? 'master' : CONFIG.apiEnvironment;
  const tenant = CONFIG.tenant === 'palmira' ? 'colombia' : CONFIG.tenant;
  return `${tenant}-${index}-${searchEnv}`;
};

const baseHeaders = {
  'Content-Type': 'application/json',
  'x-api-key': CONFIG.apigatewayKey,
};

const backendAPI = axios.create({ baseURL: CONFIG.backendURL, headers: { ...baseHeaders } });

const userApi = axios.create({
  baseURL: CONFIG.userServiceURL,
  headers: {
    ...baseHeaders,
    'x-tenant': CONFIG.tenant === 'palmira' ? 'colombia' : CONFIG.tenant,
  },
});

const userSearchApi = axios.create({
  baseURL: CONFIG.userServiceURL,
  headers: {
    ...baseHeaders,
    'x-external-key': CONFIG.userSearchExternalKey,
  },
});

const searchApi = axios.create({
  baseURL: CONFIG.searchEngineURL,
  headers: {
    ...baseHeaders,
    'x-tenant': CONFIG.tenant === 'palmira' ? 'colombia' : CONFIG.tenant,
    'x-index': getSearchIndex('campuses'),
  },
});

const webhooksAPI = axios.create({
  baseURL: CONFIG.webhooksURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const geoToolsAPI = axios.create({
  baseURL: CONFIG.geoToolsBaseURL,
  headers: {
    ...baseHeaders,
  },
});

const messagingApi = axios.create({
  baseURL: CONFIG.messagingServiceBaseURL,
  headers: {
    ...baseHeaders,
  },
});

const widgetsProfileApi = axios.create({
  baseURL: CONFIG.staticWidgetsBaseURL,
  headers: {
    ...baseHeaders,
  },
});

const analyticsApi = axios.create({
  baseURL: CONFIG.analyticsApiURL,
  headers: {
    ...baseHeaders,
  },
});
const widgetsApi = axios.create({
  baseURL: CONFIG.widgetsApiURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
const digitalEnrollmentResumedAPI = axios.create({
  baseURL: CONFIG.digitalEnrollmentURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
const digitalEnrollmentAdmissionsAPI = axios.create({
  baseURL: CONFIG.digitalEnrollmentAdmissionsURL,
  headers: {
    'Content-Type': 'application/json',
    'x-tenant': CONFIG.tenant === 'palmira' ? 'colombia' : CONFIG.tenant,
  },
});

const paymentsAPI = axios.create({
  baseURL: CONFIG.paymentsURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const publicRecordsAPI = axios.create({
  baseURL: CONFIG.publicRecordsURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiInstagram = axios.create({ baseURL: CONFIG.instagramAPI });
const apiMeta = axios.create({ baseURL: CONFIG.metaAPI });

let storeAPI = null;
backendAPI.setStore = (store) => {
  storeAPI = store;
};

apiInstagram.setStore = (store) => {
  storeAPI = store;
};

apiMeta.setStore = (store) => {
  storeAPI = store;
};

searchApi.setStore = (store) => {
  storeAPI = store;
};

geoToolsAPI.setStore = (store) => {
  storeAPI = store;
};

messagingApi.setStore = (store) => {
  storeAPI = store;
};

widgetsProfileApi.setStore = (store) => {
  storeAPI = store;
};

userApi.setStore = (store) => {
  storeAPI = store;
};

userSearchApi.setStore = (store) => {
  storeAPI = store;
};

digitalEnrollmentResumedAPI.setStore = (store) => {
  storeAPI = store;
};

digitalEnrollmentAdmissionsAPI.setStore = (store) => {
  storeAPI = store;
};

paymentsAPI.setStore = (store) => {
  storeAPI = store;
};

publicRecordsAPI.setStore = (store) => {
  storeAPI = store;
};

/**
 * Interceptor to add the locale to the request params
 * @param {*} axiosInstance
 * @example
 * const api = axios.create({ baseURL: 'www.foo.com' });
 * useLocaleInterceptor(api); // Adds the locale to the request params
 * api.get('bar'); // Adds the locale to the request params
 * // [GET] www.foo.com/bar?language_code=es
 */
const useLocaleInterceptor = ({ axiosInstance }) => {
  axiosInstance.interceptors.request.use((config) => {
    config.params = { ...config.params, language_code: i18n.locale || TENANT_CONFIGURATION.DEFAULTS.LOCALE };
    return config;
  });
};

/**
 * Interceptor to add the token to the request headers if it exists
 * @param {*} axiosInstance
 * @param {*} allowGuest
 */
const useAuthTokenInterceptor = ({ axiosInstance, allowGuest = false }) => {
  axiosInstance.interceptors.request.use((config) => {
    const loggedToken = storeAPI.getters['authentication/loggedToken'];
    const sessionToken = storeAPI.getters['authentication/sessionToken'];
    const token = (allowGuest ? (sessionToken) : loggedToken) || '';
    if (token) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      };
    } else {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
      };
    }
    return config;
  });
};

/**
 * Interceptor to refresh the token if it's about to expire in the next 5 minutes
 * Also assigns the token to the request headers, even if it's a guest token
 * @param {*} axiosInstance
 */
const useSessionManagementInterceptor = ({ axiosInstance }) => {
  axiosInstance.interceptors.response.use((config) => {
    config.params = { ...config.params, language_code: i18n.locale || 'es' };
    const isGuest = storeAPI.getters['authentication/isGuest'];
    const token = storeAPI.getters['authentication/loggedToken'];
    if (!isGuest) {
      const now = moment.utc();
      const { exp } = jwtDecode(token);
      const expirationDate = moment.unix(exp).utc();

      const timeToExpiration = expirationDate.diff(now);

      const tolerance = 1000 * 60 * 5; // 5 minutes

      if (timeToExpiration < tolerance) {
        // Token hasn't expired yet, but it will in the next 5 minutes, so we refresh it
        userApi.post('token/refresh/', { token })
          .then((response) => {
            // Positive response, we update the token
            const refreshedToken = response.data.token;
            storeAPI.dispatch('authentication/setUserToken', { token: refreshedToken });
          })
          .catch((error) => {
            // Token expired, we log out
            if (error.response.status === 400) {
              storeAPI.dispatch('authentication/logoutSuccess');
            }
          });
      }
    }
    return config;
  });

  // Loads the Auth Token Header, with guest token if the user is not logged in
  useAuthTokenInterceptor({ axiosInstance, allowGuest: true });
};

/**
 * Interceptor to add the API Gateway key to the request headers. This is used for endpoints that
 * are not protected by the Tether token, but are protected by the API Gateway key instead
 * @param {*} axiosInstance
 */
const useApiGatewayKeyInterceptor = ({ axiosInstance }) => {
  if (CONFIG.apigatewayKey) {
    axiosInstance.interceptors.request.use((config) => {
      config.headers['x-api-key'] = CONFIG.apigatewayKey;
      return config;
    });
  }
};

/**
 * Interceptor to add a trailing slash to the request URL if it doesn't have one
 * @param {*} axiosInstance
 * @example
 * const api = axios.create({ baseURL: 'www.foo.com' });
 * useTailingSlashInterceptor(api); // Adds the trailing slash to the request URL
 * api.get('bar'); // [GET] www.foo.com/bar/
 * api.get('bar/'); // [GET] www.foo.com/bar/
 */
const useTailingSlashInterceptor = ({ axiosInstance }) => {
  axiosInstance.interceptors.request.use((config) => {
    if (config.url.substr(-1) !== '/') {
      // eslint-disable-next-line no-param-reassign
      config.url = `${config.url}/`;
    }
    return config;
  });
};

/**
 *  INTERCEPTOR LOADING
 */

// The BackendAPI is the only one that uses the session management interceptor to handle the token
// lifetime, and guest tokens
useSessionManagementInterceptor({ axiosInstance: backendAPI });

// Some APIs need to add a trailing slash to the request URL
[
  backendAPI,
  geoToolsAPI,
  searchApi,
].forEach((axiosInstance) => useTailingSlashInterceptor({ axiosInstance }));

// APIs that require the Tether token to be sent in the Authorization header
// Backend API is not included because it handles the token differently (see useSessionManagementInterceptor)
[
  digitalEnrollmentAdmissionsAPI,
  digitalEnrollmentResumedAPI,
  paymentsAPI,
  userApi,
].forEach((axiosInstance) => useAuthTokenInterceptor({ axiosInstance }));

// APIs that need to know the current locale to translate the response accordingly
[
  backendAPI,
  digitalEnrollmentAdmissionsAPI,
  digitalEnrollmentResumedAPI,
  geoToolsAPI,
  messagingApi,
  paymentsAPI,
  searchApi,
  userApi,
  userSearchApi,
].forEach((axiosInstance) => useLocaleInterceptor({ axiosInstance }));

// APIs that need to send the x-api-key header to the API Gateway
[
  userApi,
  userSearchApi,
].forEach((axiosInstance) => useApiGatewayKeyInterceptor({ axiosInstance }));

// Other interceptors (see whether we should enhance them)

backendAPI.interceptors.response.use(
  (response) => {
    storeAPI.commit('loading/loading', false);
    return response;
  },
  (error) => {
    storeAPI.commit('loading/loading', false);
    if (error.response && error.response.status) {
      const { status } = error.response;
      if (status === 500) {
        storeAPI.dispatch('utils/error', 'errors.500');
        // TODO: on-api-snackbar
        router.push({ name: 'Login' });
      }
      if (status === 400 && error.response.data.msg !== 'Identification Number Not Found' ) {
        let errors = '';
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(error.response.data)) {
          if (key === 'non_field_errors') {
            errors = `${value}\n`;
          } else {
            errors = `${key}: ${value}\n`;
          }
        }
        storeAPI.dispatch('utils/error', errors);
        // TODO: 'on-api-snackbar'
      }
      if (status === 401) {
        // Expired Token

        // FIXME: This queryParam logic is not working because the stores are cleared before
        const inDigitalEnrollment = storeAPI.getters['digitalEnrollment/inDigitalEnrollment'];
        const { origin, redirectURL } = storeAPI.getters['authentication/externalLoginInfo'];
        const externalLoginQueryParams = inDigitalEnrollment ? { origin, redirect_url: redirectURL } : {};
        const otherQueryParams = inDigitalEnrollment ? storeAPI.getters['digitalEnrollment/snakeCaseParameters'] : {};
        const queryParams = { ...externalLoginQueryParams, ...otherQueryParams };
        storeAPI.dispatch('utils/error', 'errors.token_expired');
        // TODO: 'on-api-snackbar'
        storeAPI.dispatch('authentication/logoutSuccess', {}, { root: true });
        storeAPI.commit('authentication/reset', {}, { root: true });
        storeAPI.commit('institutions/reset', {}, { root: true });
        router.push({ name: 'Login', query: queryParams });
      }
    }
    return Promise.reject(error);
  },
);

publicRecordsAPI.interceptors.request.use((config) => {
  config.params = { ...config.params };
  return config;
});

userApi.interceptors.response.use(
  (response) => {
    storeAPI.commit('loading/loading', false);
    return response;
  },
  (error) => {
    // 1. Set all loaders to false
    storeAPI.commit('loading/loading', false);
    storeAPI.commit('authentication/setLoginLoading', { bool: false });
    storeAPI.commit('authentication/setLinkLoading', { bool: false });

    // 2. Display response-specific messages
    if (error.response && error.response.status) {
      const { status } = error.response;
      if (status === 500) {
        storeAPI.dispatch('utils/error', 'errors.500');
        // TODO: on-api-snackbar
        // TODO: Figure out why we're going to the login when 500 is returned
        router.push({ name: 'Login' });
      }
      if (status === 401) {
        // 3. IF the token has expired, clear the necessary stores and move to login
        storeAPI.dispatch('utils/error', 'errors.token_expired');
        // TODO: We have to separate the logic of store reset. In each store, theres some states that should
        // be cleared and some that shouldn't -- User specific should be cleared, core app things not.
        storeAPI.commit('authentication/reset', {}, { root: true });
        storeAPI.commit('institutions/reset', {}, { root: true });
        router.push({ name: 'Login' });
      }
    }
    // Return the rejection
    return Promise.reject(error);
  },
);

export {
  analyticsApi,
  apiInstagram,
  apiMeta,
  backendAPI,
  digitalEnrollmentAdmissionsAPI,
  digitalEnrollmentResumedAPI,
  geoToolsAPI,
  messagingApi,
  paymentsAPI,
  publicRecordsAPI,
  searchApi,
  userApi,
  userSearchApi,
  widgetsApi,
  widgetsProfileApi,
  webhooksAPI,
};
