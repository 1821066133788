var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-primary-dark w-full d-flex flex-column"},[_c('GmapMap',{staticClass:"map",attrs:{"id":"map","center":_vm.getLocationStudent,"zoom":13.3,"options":{
      disableDefaultUI: true,
      clickableIcons: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      scrollwheel: false,
      draggable: false,
      styles: _vm.stylesGoogleMap,
      gestureHandling: 'greedy',
    }}},[_c('GmapCustomMarker',{attrs:{"id":"student-location","marker":_vm.getLocationStudent}},[_c('img',{attrs:{"src":require(`@/assets/feedback/marker-student.svg`),"width":"18","alt":"marker student"}})]),(false)?[_vm._l((_vm.getLocationRecommendations),function(marker,index){return _c('GmapCustomMarker',{key:index + Math.random(),attrs:{"marker":marker}},[_c('img',{attrs:{"src":require(`@/assets/feedback/marker-white.svg`),"width":"18","alt":"marker student"}})])}),_vm._l((_vm.getLocationRecommendationsPaymentPerformance),function(marker,index){return _c('GmapCustomMarker',{key:index + Math.random(),attrs:{"marker":marker}},[_c('img',{attrs:{"src":require(`@/assets/feedback/marker-blue.svg`),"width":"18","alt":"marker student"}})])})]:_vm._e(),_vm._l((_vm.getLocationApplications),function(marker,index){return _c('GmapCustomMarker',{key:index + Math.random(),attrs:{"marker":marker}},[_c('img',{attrs:{"src":require(`@/assets/feedback/marker-favorite.svg`),"width":"18","alt":"marker student"}})])}),_c('GmapCircle',{attrs:{"id":"student-location-radius","center":_vm.getLocationStudent,"radius":3000,"visible":true,"options":_vm.mapCircle}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }