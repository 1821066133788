import { backendAPI } from '@/api';

const recommendations = '/reports/recommendations_v3/';
const application = '/reports/risk/simulate';
const results = '/reports/results/postulations';
const simulateFamily = '/reports/risk/simulate_family';

export default {
  recommendations(uuid) {
    return backendAPI.get(`${recommendations}`, {
      params: {
        applicant_uuid: uuid,
        year: 2023,
        round: 2,
      },
    });
  },

  application(uuid) {
    return backendAPI.get(`${application}/`, {
      params: {
        applicant_uuid: uuid,
        year: 2023,
        round: 2,
      },
    });
  },

  simulateFamily() {
    return backendAPI.get(`${simulateFamily}/`, {
      params: {
        year: '2023',
        round: 2,
      },
    });
  },

  results() {
    return backendAPI.get(`${results}/`, {
      params: {
        year: '2024',
        admission_system_label_id: 3,
        round: 3,
      },
    });
  },
};
