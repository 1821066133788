import Vue from 'vue';
import VueRouter from 'vue-router';
import CONFIG from '@/config';
import Login from '@/views/authentication/Login.vue';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { setDefaultGuestData, handleRouteQuery } from '@/utils/filters/routes';

Vue.use(VueRouter);

let storeRouter = null;

const isLogged = () => storeRouter.getters['authentication/authDone'];
const isNewVisitor = () => storeRouter.getters['authentication/isNewVisitor'];
const isGuest = () => storeRouter.getters['authentication/isGuest'];
const isHeadmaster = () => storeRouter.getters['roles/isHeadMaster'];
const isAmbassador = () => storeRouter.getters['roles/isAmbassador'];
const validUser = () => storeRouter.getters['authentication/preCheckStatus'];
const exitsInfoGuest = () => storeRouter.getters['authentication/exitsInfoGuest'];
const loginModal = () => storeRouter.getters['authentication/loginModal'];

const routes = [
  // waiting list
  {
    path: '/waiting_list/:campusCode/:programId',
    name: 'Waiting List',
    component: () => import(/* webpackChunkName: "schools" */ '../views/explorer/WaitingList.vue'),
    props: { fromLink: true },
  },
  // authentication
  {
    path: '/login',
    query: { params: null },
    component: () => import(/* webpackChunkName: "Main" */ '../views/Main.vue'),
    beforeEnter: (to, from, next) => {
      // TODO: handle navigation to login in case of logged user
      const inExternalLogin = to.query.redirect_url !== undefined;
      const internalLogin = to.query.internalDgeLogin;
      if (internalLogin) {
        return storeRouter.dispatch('authentication/setInternalDgeLoginFlow', { loginQueryParams: to.query }).then(() => {
          next();
        });
      }
      if (inExternalLogin && isLogged()) {
        return storeRouter.dispatch('authentication/logoutSuccess').then(() => {
          storeRouter.dispatch('authentication/setExternalLoginFlow', { loginQueryParams: to.query }).then(() => {
            next();
          });
        });
      }
      return storeRouter.dispatch('authentication/setExternalLoginFlow', { loginQueryParams: to.query }).then(({ validExternalLogin }) => {
        if (isLogged() && !validExternalLogin && loginModal() !== 'applicationFlow') {
          next('/map');
        } else {
          next();
        }
      });
    },
    props: true,
    children: [
      {
        path: '/',
        component: Login,
        name: 'Login',
        props: true,
      },
      {
        path: 'phone_reset',
        component: Login,
        name: 'Login',
        beforeEnter: (to, from, next) => {
          storeRouter.dispatch('userRegister/setForcedStep', { step: 43 }).then(() => {
            next();
          });
        },
        props: true,
      },
    ],
  },
  {
    path: '/new_password',
    component: () => import(/* webpackChunkName: "Main" */ '../views/Main.vue'),
    props: (route) => ({ token: route.query.token }),
    children: [
      {
        path: '/',
        name: 'Reset Password',
        component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/authentication/ResetPassword.vue'),
      },
    ],
  },
  {
    path: '/pilot_login/:uuid',
    name: 'Pilot Login',
    component: () => import(/* webpackChunkName: "LoginWithUuid" */ '../views/authentication/LoginWithUuid.vue'),
    props: true,
  },
  {
    path: '/headmaster_register/:userUuid/:code/:partnerId',
    name: 'Headmaster Register',
    component: () => import(/* webpackChunkName: "HeadmasterRegister" */ '../views/authentication/HeadmasterRegister.vue'),
    props: true,
  },
  {
    path: '/preexist_login/:userUuid/',
    name: 'Preexist login',
    redirect: '/research/:userUuid/',
  },
  {
    // PAth has optional research id
    path: '/research/:researchId?',
    name: 'Research',
    component: () => import(/* webpackChunkName: "preLogin" */ '../views/research/Research.vue'),
    props: true,
    meta: {
      disabled: !TENANT_CONFIGURATION.SETTINGS.RESEARCH.ENABLED,
      hideChat: true,
    },
    beforeEnter: (to, from, next) => {
      const { researchId } = to.params;
      // TODO: Organic research is broken, so we are redirecting to the explorer if no id is provided
      const { ENABLED, ORGANIC } = TENANT_CONFIGURATION.SETTINGS.RESEARCH;
      next((ENABLED && (researchId || ORGANIC)) ? undefined : { name: 'Explorer' });
    },
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "Main" */ '../views/Main.vue'),
    beforeEnter: (to, from, next) => {
      const firstTime = isNewVisitor();
      storeRouter.dispatch('authentication/setIsNewVisitor', firstTime);
      const nextGuard = () => {
        const { ENABLED, ORGANIC, FOR_NEW_USERS } = TENANT_CONFIGURATION.SETTINGS.RESEARCH;
        if (firstTime && ENABLED && ORGANIC && FOR_NEW_USERS) {
          next({ name: 'Research' });
        } else {
          next();
        }
      };
      handleRouteQuery(to.query, { store: storeRouter, next: nextGuard });
    },
    children: [
      {
        path: '(map)?',
        name: 'Explorer',
        component: () => import(/* webpackChunkName: "schools" */ '../views/explorer/Explorer.vue'),
        props: true,
        children: [
          {
            path: 'school/:code/:name?',
            name: 'ExplorerSchool',
            props: true,
          },
        ],
      },
      {
        path: 'favorites',
        name: 'Favorites',
        component: () => import(/* webpackChunkName: "schools" */ '../views/explorer/Favorites.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'application',
        name: 'Application',
        component: () => import(/* webpackChunkName: "schools" */ '../views/explorer/Application.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'simulate',
        name: 'Simulate',
        component: () => import(/* webpackChunkName: "schools" */ '../views/explorer/Simulate.vue'),
        meta: {
          requiresAuth: true,
          disabled: !TENANT_CONFIGURATION.SETTINGS.SIMULATION.ENABLED,
        },
      },
    ],
  },
  // dashboard
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "schools" */ '../views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      storeRouter.dispatch(
        'utils/setBreadcrumbs',
        {
          breadcrumbs: [
            {
              text: 'dashboard.breadcrumbs.panel',
              route: '/dashboard',
            },
          ],
          setType: 'set',
        },
      ).then(() => {
        next();
      });
    },
  },
  // headmaster sites
  {
    path: '/Teaching',
    name: 'Teaching',
    component: () => import('../views/dashboard/Teaching.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
    },
  },
  {
    path: '/admissions',
    name: 'Admissions',
    component: () => import(/* webpackChunkName: "schools" */ '../views/dashboard/Admissions.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
    },
    beforeEnter: (to, from, next) => {
      storeRouter.dispatch(
        'utils/setBreadcrumbs',
        {
          breadcrumbs: [
            {
              text: 'dashboard.breadcrumbs.panel_selected',
              route: '/dashboard',
            },
            {
              text: 'dashboard.breadcrumbs.admission',
              route: '/admissions',
            },
          ],
        },
      ).then(() => {
        next();
      });
    },
    props: true,
  },
  {
    path: '/digital-profile',
    name: 'DigitalProfile',
    component: () => import(/* webpackChunkName: "schools" */ '../views/dashboard/DigitalProfile.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
    },
    beforeEnter: (to, from, next) => {
      storeRouter.dispatch(
        'utils/setBreadcrumbs',
        {
          breadcrumbs: [
            {
              text: 'dashboard.breadcrumbs.panel_selected',
              route: '/dashboard',
            },
            {
              text: 'dashboard.breadcrumbs.digital_profile',
              route: '/digital-profile',
            },
          ],
        },
      ).then(() => {
        next();
      });
    },
    props: true,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "schools" */ '../views/dashboard/Notifications.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
    },
    beforeEnter: (to, from, next) => {
      storeRouter.dispatch(
        'utils/setBreadcrumbs',
        {
          breadcrumbs: [
            {
              text: 'dashboard.breadcrumbs.panel_selected',
              route: '/dashboard',
            },
            {
              text: 'dashboard.breadcrumbs.notifications',
              route: '/notifications',
            },
          ],
        },
      ).then(() => {
        next();
      });
    },
    props: true,
  },
  {
    path: '/partnership/:id',
    name: 'Partnership',
    params: null,
    component: () => import(/* webpackChunkName: "partnership" */ '../views/dashboard/Partnership.vue'),
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import(/* webpackChunkName: "schools" */ '../views/dashboard/Gallery.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
    },
    beforeEnter: (to, from, next) => {
      storeRouter.dispatch(
        'utils/setBreadcrumbs',
        {
          breadcrumbs: [
            {
              text: 'dashboard.breadcrumbs.panel_selected',
              route: '/dashboard',
            },
            {
              text: 'dashboard.breadcrumbs.gallery',
              route: '/gallery',
            },
          ],
        },
      ).then(() => {
        next();
      });
    },
    props: true,
  },
  // end headmaster sites
  {
    path: '/digital-enrollment',
    name: 'DigitalEnrollment',
    component: () => import(/* webpackChunkName: "admissions" */ '../views/dashboard/DigitalEnrollment.vue'),
    beforeEnter: (to, from, next) => {
      // Load breadcrumbs and applications
      const breadcrumbs = [
        {
          text: 'dashboard.breadcrumbs.panel_selected',
          route: '/dashboard',
        },
        {
          text: 'dashboard.breadcrumbs.applications',
          route: '/digital-enrollment',
        },
      ];
      if (isLogged() && !isHeadmaster() && !isAmbassador()) {
        storeRouter.dispatch('utils/setBreadcrumbs', { breadcrumbs }).then(() => {
          storeRouter.dispatch('digitalEnrollment/getDgeResumeLegalGuardian').then(() => {
            next();
          });
        });
      } else {
        next('/dashboard');
      }
    },
    props: true,
  },
  {
    path: '/digital-enrollment/application/:id/:status?',
    name: 'DigitalEnrollmentApplication',
    component: () => import(/* webpackChunkName: "admissions" */ '../views/dashboard/DigitalEnrollment.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
      redirectTo: '/dashboard',
    },
    beforeEnter: (to, from, next) => {
      const { id: applicationId, status } = to.params;
      // Search for the application in the store

      storeRouter.dispatch('digitalEnrollment/getDgeResumeLegalGuardian', { useCache: true }).then((applications) => {
        const application = applications.find((app) => app.id === applicationId);

        if (application) {
          storeRouter.dispatch('digitalEnrollment/setDgeSelectedApplication', { application }).then(() => {
            if (status) {
              // TODO: 1 Test this. 2. See whether we can use the callback in the modal to remove the status from the
              // URL after the modal is closed
              const re = /(?<context>\w+)-(?<status>\w+)/;
              const { context, status: statusName } = status.match(re).groups;
              const modalMappings = {
                payment: 'PaymentStatus',
              };
              const modalName = modalMappings[context];
              storeRouter.dispatch('utils/activateModal', {
                name: modalName,
                data: {
                  status: statusName,
                },
              });
              if (modalName === 'PaymentStatus') {
                // FIXME: Hack to log the successful payment time in the store when the navigation is done
                // via callback
                storeRouter.dispatch('digitalEnrollment/logSuccessfulPaymentTime');
              }
              next({
                name: 'DigitalEnrollmentApplication',
                params: {
                  id: applicationId,
                },
                replace: true,
              });
            } else {
              next();
            }
          });
        } else {
          next('/digital-enrollment');
        }
      });
    },
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import('../views/dashboard/AnalyticsSelection.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
    },
    beforeEnter: (to, from, next) => {
      storeRouter.dispatch(
        'utils/setBreadcrumbs',
        {
          breadcrumbs: [
            {
              text: 'dashboard.breadcrumbs.panel_selected',
              route: '/dashboard',
            },
            {
              text: 'dashboard.breadcrumbs.analytics',
              route: '/analytics',
            },
          ],
        },
      ).then(() => {
        next();
      });
    },
  },
  {
    path: '/analytics/sections/:section',
    name: 'AnalyticsSection',
    component: () => import('../views/dashboard/AnalyticsVacancies.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
    },
  },
  {
    path: '/analytics/instagram',
    name: 'AnalyticsInstagram',
    component: () => import('../views/dashboard/AnalyticsInstagram.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
    },
  },
  {
    path: '/analytics/vacancies',
    name: 'AnalyticsVacancies',
    component: () => import('../views/dashboard/AnalyticsVacancies.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
    },
  },
  {
    path: '/analytics/paes',
    name: 'AnalyticsPaes',
    component: () => import('../views/dashboard/AnalyticsPaes.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
    },
  },
  {
    path: '/my-payments',
    name: 'MyPayments',
    component: () => import('../views/payments/MyPayments.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: [isHeadmaster, isAmbassador],
      inDevelopment: true,
    },
    beforeEnter: (to, from, next) => {
      storeRouter.dispatch('payments/setRedirectRoute', { route: from || '/' });
      storeRouter.dispatch('payments/getPayments').then(() => {
        storeRouter.dispatch('payments/resetPaymentFlow').then(() => {
          next();
        });
      });
    },
  },
  {
    path: '/payment/:id/:status?',
    name: 'Payment',
    component: () => import('../views/payments/Payments.vue'),
    meta: {
      requiresAuth: true,
      // TODO: Check if we need to restrict this to headmasters and ambassadors
      // authorizedRoles: [isHeadmaster, isAmbassador],
      redirectTo: '/dashboard',
    },
    beforeEnter: (to, from, next) => {
      storeRouter.dispatch('payments/setRedirectRoute', { route: from });
      const comesFromApplication = from.name === 'DigitalEnrollmentApplication';
      storeRouter.dispatch('payments/getPayment', { id: to.params.id, useCache: comesFromApplication }).then((payment) => {
        storeRouter.dispatch('payments/getAvailablePaymentMethods', { entity: payment.entity }).then(() => {
          next();
        });
      });
    },
  },
  {
    path: '/feedback/:uuid/:interventionId/:status?',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "LoginWithUuid */ '../views/authentication/LoginWithUuid.vue'),
  },
  {
    path: '/gob-plugin/:pluginCode',
    redirect: (to) => ({
      name: 'Explorer',
      query: {
        landing: to.params.pluginCode,
      },
    }),
  },
  {
    path: '*',
    redirect: '/map',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Ensures that there's a session (even if guest) before entering the route
router.beforeEach(async (to, from, next) => {
  if ((!validUser() || !validUser().is_verified) && !isGuest()) {
    storeRouter.dispatch('authentication/logoutSuccess').then(() => {
      setDefaultGuestData({ store: storeRouter, next });
    });
  } else if (to.path.includes('/map/school/') || isLogged()) {
    next();
  } else {
    storeRouter.dispatch('authentication/checkGuestToken').then(() => {
      if (exitsInfoGuest()) {
        next();
      } else {
        setDefaultGuestData({ store: storeRouter, next });
      }
    });
  }
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== 'Login'
    && from.name !== 'Dashboard'
    && (from.name === null && to.name !== 'DigitalEnrollment')) {
    storeRouter.dispatch('authentication/setExternalLoginFlow', { loginQueryParams: to.query });
  }
  next();
});

router.beforeEach((to, from, next) => {
  const {
    requiresAuth,
    authorizedRoles,
    redirectTo,
    disabled,
    inDevelopment,
  } = to.meta;

  const redirect = (routeIdentifier) => {
    const identifier = routeIdentifier || redirectTo || '/map';
    const route = router.resolve(identifier);
    if (to.name !== route.name) {
      next(route);
    } else {
      next();
    }
  };
  if (disabled || (inDevelopment && CONFIG.environment === 'production')) {
    redirect();
  } else if (requiresAuth && isGuest()) {
    redirect('/login');
  } else if (authorizedRoles && !authorizedRoles.some((role) => role())) {
    redirect();
  } else {
    next();
  }
});

router.setStore = (store) => {
  storeRouter = store;
};

export default router;
