<template>
  <span class="loader" />
</template>

<script>
export default {
  name: 'LoaderCircles',
};
</script>
<style lang="scss" scoped>
.loader {
  width: 84px;
  height: 84px;
  position: relative;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: #62C7D3;
  animation: push 1s infinite linear alternate;
}
.loader:after {
  top: auto;
  bottom: 0;
  left: 0;
  background: white;
  animation-direction: alternate-reverse;
}

@keyframes push {
  0% {
    width: 14px;
    height: 14px;
  }
  100% {
    width: 84px;
    height: 84px;
  }
}

</style>
